import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { RadioButtonChecked as RecordIcon } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';
import DeferRenderWrapper from 'plantiga-common/DeferRenderWrapper';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import AthleteAvatar from 'plantiga-component/Athletes/Avatar';
import { PairedPodChips } from 'plantiga-component/Devices/PairedPodChips';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import useTeam from 'plantiga-firebase/Team/useTeam';

import MoreMenu, { MoreMenuSkeleton } from './MoreMenu';

const useStyles = makeStyles()({
  secondaryAction: {
    display: 'flex',
  },
});

type Props = {
  readonly athleteId: string;
};

export function PersonListItem({ athleteId }: Props) {
  const { classes } = useStyles();
  const { teamId } = useTeam();
  const athlete = useAthlete(athleteId);
  const navigateURL = useNavigateURL();
  const userCanRecord = usePrivilege('activity:record');

  const recordDisabled = !userCanRecord || athlete.secondary_team === true;
  return (
    <>
      <ListItem button onClick={() => navigateURL('athlete', { teamId, athleteId })}>
        <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
          <AthleteAvatar athlete={athlete} />
        </ListItemIcon>
        <ListItemText
          primary={<b>{athlete?.name ?? '-'}</b>}
          primaryTypographyProps={{ noWrap: true }}
        />
        {athlete.secondary_team ? null : (
          <ListItemSecondaryAction className={classes.secondaryAction}>
            <PairedPodChips hidePairButton athleteId={athleteId} />
            <DeferRenderWrapper delay={100} placeholder={<MoreMenuSkeleton />}>
              {() => <MoreMenu athleteId={athleteId} />}
            </DeferRenderWrapper>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <ListItem
        button
        divider
        disabled={recordDisabled}
        onClick={() => navigateURL('record', { teamId }, { people: athleteId })}
      >
        <ListItemIcon style={{ display: 'flex', justifyContent: 'center' }}>
          <RecordIcon />
        </ListItemIcon>
        <ListItemText>Record</ListItemText>
      </ListItem>
    </>
  );
}
