import React from 'react';

import { isSameDay } from 'date-fns';
import { groupBy, mapValues, meanBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { IconButton } from '@mui/material';

import { ConstrainedToParentSize } from 'plantiga-common/ConstrainedToParentSize';
import { formatFieldName, useFields } from 'plantiga-common/Fields';
import Popover from 'plantiga-common/Popover';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

import { teamDashboardTemplate, templateFieldId, templateTitle } from '../constants';
import type { TeamDashDatum } from '../useTeamDashboard';

import { Plot } from './Plot';
import type { BaseDatum } from './typedefs';

const useStyles = makeStyles()(() => ({
  iconButton: {
    width: 48,
    height: 48,
    padding: 0,
  },
  iconButtonLabel: {
    width: '100%',
    height: '100%',
  },
}));

const TEMPLATE = teamDashboardTemplate.radar;
const PLOT_SIZE = 420;

type Props = {
  athleteId: string;
  date: Date;
  data: TeamDashDatum[] | undefined;
};

export function CompareRadarPlot({ athleteId, date, data }: Props) {
  const { classes } = useStyles();
  const athlete = useAthlete(athleteId);
  const { getField } = useFields();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const athleteValues = data
    ?.filter((v) => v.athleteId === athleteId && isSameDay(v.date, date))
    ?.reduce<{ [fieldId: string]: number | undefined }>(
      (a, v) => Object.assign(a, { [v.fieldId]: Math.abs(v.value) }),
      {},
    );

  const teamAvgs: { [fieldId: string]: number | undefined } = mapValues(
    groupBy(data, 'fieldId'),
    (v) => meanBy(v, (vv) => Math.abs(vv.value)),
  );

  const athleteData: BaseDatum[] = Object.values(TEMPLATE).map((v) => ({
    label:
      templateTitle(v) ??
      formatFieldName(getField(templateFieldId(v)), 'metric', { abbreviate: true })
        .toString()
        .replaceAll(',', ''),
    fieldId: templateFieldId(v),
    value: athleteValues?.[templateFieldId(v)] ?? 0,
  }));

  const teamData: BaseDatum[] = Object.values(TEMPLATE).map((v) => ({
    label:
      templateTitle(v) ??
      formatFieldName(getField(templateFieldId(v)), 'metric', { abbreviate: true })
        .toString()
        .replaceAll(',', ''),
    fieldId: templateFieldId(v),
    value: teamAvgs[templateFieldId(v)] ?? 0,
  }));

  // don't show the button for athlete's with no data
  if (!athleteData.find((v) => v.value > 0)) return null;
  return (
    <div>
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        classes={{ root: classes.iconButton, label: classes.iconButtonLabel }}
        size="large"
      >
        <ConstrainedToParentSize>
          {(parent) => (
            <Plot
              icon
              width={parent.width}
              height={parent.height}
              athleteData={athleteData}
              teamData={teamData}
              athleteName={athlete.name}
              margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
            />
          )}
        </ConstrainedToParentSize>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Plot
          width={PLOT_SIZE}
          height={PLOT_SIZE}
          athleteData={athleteData}
          teamData={teamData}
          athleteName={athlete.name}
        />
      </Popover>
    </div>
  );
}
