import React from 'react';
import { useHistory } from 'react-router';

import { ChevronLeft as ChevronLeftIcon, HelpOutline } from '@mui/icons-material';
import { Divider, Drawer, IconButton, List, Toolbar } from '@mui/material';

import Logo from 'plantiga-assets/logo-with-lockup.svg?react';
import useBreakpoint from 'plantiga-common/useBreakpoint';
import makeStyles from 'plantiga-util/MUI/makeStyles';
import { external } from 'plantiga-util/urls';

import VersionDisplay from '../Notifications/VersionDisplay';

import AccountDrawerItem from './AccountDrawerItem';
import { ContactDrawerItem } from './ContactDrawerItem';
import DrawerItem from './DrawerItem';
import DrawerTreeItem from './DrawerTreeItem';
import TeamSelect from './TeamSelect';
import useNavItems from './useNavItems';

export const DRAWER_WIDTH_OPEN = 265;

const useStyles = makeStyles<void, 'closeIcon'>()((theme, _params, classes) => ({
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [`&:hover .${classes.closeIcon}`]: {
      opacity: 1,
    },
  },
  drawerPaper: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowX: 'hidden',
    width: DRAWER_WIDTH_OPEN,
    backgroundColor: theme.palette.background.paper,
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    paddingLeft: `env(safe-area-inset-left)`,
  },
  logo: {
    ...theme.mixins.plantigaLogo,
    marginLeft: -9,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
  teamSelect: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  closeIcon: {
    opacity: 0,
    // disable the :hover interaction on mobile because it requires an extra click
    // to register interactions with the app elements
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      opacity: 1,
    },
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  readonly open?: boolean;
  readonly onClose: () => void;
};

// let observing elements know that there is a change in window size
// TODO: does this belong in ContentContainer instead?
function triggerWindowResize() {
  window.dispatchEvent(new Event('resize'));
}

export default function AppDrawer({ open, onClose }: Props) {
  const { classes } = useStyles();
  const isMobile = useBreakpoint();
  const { items, params } = useNavItems();
  const history = useHistory();

  React.useEffect(
    () => (isMobile ? history.listen(onClose) : undefined),
    [isMobile, history, onClose],
  );

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onClose={onClose}
      SlideProps={{
        onExited: triggerWindowResize,
        onEntered: triggerWindowResize,
      }}
    >
      <div className={classes.content}>
        <Toolbar className={classes.toolbar}>
          <Logo fill="white" className={classes.logo} />
          <IconButton size="small" onClick={onClose} className={classes.closeIcon}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <div className={classes.teamSelect}>
          <TeamSelect />
        </div>
        <List>
          {items.map((item) => (
            <DrawerTreeItem key={item.path} params={params} {...item} />
          ))}
        </List>
        <Divider />
        <List>
          <AccountDrawerItem />
          <ContactDrawerItem />
          <DrawerItem
            id="help-button"
            icon={<HelpOutline />}
            text="Knowledge Base"
            href={external.help.guides}
          />
        </List>
      </div>
      <VersionDisplay />
    </Drawer>
  );
}
