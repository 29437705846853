import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';

import { AutocompleteActivityType } from 'plantiga-common/AutocompleteActivityType';
import { IconButtonWithLoading } from 'plantiga-common/ButtonWithLoading';
import { updateActivity } from 'plantiga-firebase/Activity/actions';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import { useActivityType } from 'plantiga-firebase/ActivityTypes/useActivityTypes';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
  },
  tableRow: {
    height: 80,
  },
});

type Props = {
  readonly activity: Activity;
  readonly activityId: string;
  readonly teamId: string;
};

export default function EditActivityType({ activity, activityId, teamId }: Props) {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [activityTypeId, setActivityTypeId] = React.useState(
    activity.labels.type_id ?? activity.labels.type,
  );
  const actType = useActivityType(activityTypeId);

  const handleSave = async () => {
    if (actType == null) return;

    setLoading(true);
    await updateActivity({
      teamId,
      activityId,
      activity: {
        ...activity,
        labels: {
          ...activity.labels,
          ...actType.defn.activity_labels,
          type_id: activityTypeId,
        },
      },
    });
    setLoading(false);
    setEditing(false);
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.title}>Activity Type</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <AutocompleteActivityType
            activityTypeId={activityTypeId}
            onChange={(v) => setActivityTypeId(v)}
            textFieldProps={{ fullWidth: true }}
          />
        ) : (
          actType?.name ?? 'Activity Type Not Found'
        )}
      </TableCell>
      {editing ? (
        <TableCell>
          <IconButtonWithLoading
            loading={loading}
            disabled={loading || actType == null}
            onClick={handleSave}
          >
            <SaveIcon />
          </IconButtonWithLoading>
        </TableCell>
      ) : (
        <TableCell>
          <IconButton onClick={() => setEditing(true)} size="large">
            <EditIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
