import React from 'react';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useParams } from 'plantiga-common/react-router-hooks';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import { getCurrentTimezone, isTimezoneEqual } from 'plantiga-util/timezone';

import AthleteTimezoneWarning from '../AthleteTimezoneWarning';

import DangerZone from './DangerZone';
import AthleteTable from './Table';

export default function InfoView() {
  const { athleteId } = useParams();
  const athlete = useAthlete(athleteId);
  const athleteTimezone = athlete.timezone;
  const currentTimezone = getCurrentTimezone();

  return (
    <ContentContainer id="aui_profile_info">
      {!isTimezoneEqual(currentTimezone, athleteTimezone, true) && <AthleteTimezoneWarning />}

      <AthleteTable athleteId={athleteId} athlete={athlete} />
      <DangerZone />
    </ContentContainer>
  );
}
