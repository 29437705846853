import { useAthlete } from '../Firestore/Athletes/useAthletes';
import useTeam from '../Firestore/Team/useTeam';

import { useDocumentTitle } from './hooks';

export function useTeamTitle(suffix?: string | null) {
  const { team } = useTeam();

  let title = team && team.name ? team.name : '';
  if (suffix != null) {
    title += ` • ${suffix}`;
  }
  useDocumentTitle(title);
}

export function useAthleteTitle(athleteId: string, suffix?: string | null) {
  const athlete = useAthlete(athleteId);

  let title = athlete.name;
  if (suffix != null) {
    title += ` • ${suffix}`;
  }
  useDocumentTitle(title);
}
