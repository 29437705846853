import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { useLocalStorage } from 'usehooks-ts';

import ConnectedSnackbar from 'plantiga-common/ConnectedSnackbar';
import Div100vh from 'plantiga-common/Div100vh';
import useBreakpoint from 'plantiga-common/useBreakpoint';

import ErrorBoundary from '../Error/Boundary';

import TeamAppBar from './AppBars/TeamAppBar';
import TeamAppBarProvider from './AppBars/TeamAppBarProvider';
import AppDrawer, { DRAWER_WIDTH_OPEN } from './AppDrawer';

type Props = {
  readonly children: any;
};

const useStyles = makeStyles()((theme) => ({
  div100vh: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    marginLeft: 0,
  },
  contentClosed: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
      marginLeft: DRAWER_WIDTH_OPEN,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));

export default function Nav({ children }: Props) {
  const { classes, cx } = useStyles();
  const isMobile = useBreakpoint();
  const [openConfig, setOpenConfig] = useLocalStorage('showAppDrawer', true);
  const [openState, setOpenState] = useState(false);

  const toggleDrawer = React.useCallback(
    (v: boolean) => {
      if (isMobile) {
        setOpenState(v);
      } else {
        setOpenConfig(v);
      }
    },
    [isMobile, setOpenConfig],
  );

  const open = isMobile ? openState : openConfig;

  return (
    <Div100vh className={classes.div100vh}>
      <TeamAppBarProvider>
        <TeamAppBar open={open} handleDrawerOpen={() => toggleDrawer(!open)} />
        <AppDrawer open={open} onClose={() => toggleDrawer(false)} />
        <div
          className={cx(classes.content, {
            [classes.contentShift]: open,
            [classes.contentClosed]: !open,
          })}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </TeamAppBarProvider>
      <ConnectedSnackbar />
    </Div100vh>
  );
}
