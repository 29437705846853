import React from 'react';

import { formatDistance } from 'date-fns';
import { some, isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import {
  CheckCircleOutlined as CheckIcon,
  ReportProblemOutlined as WarningIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import {
  Collapse,
  Paper,
  IconButton,
  alpha,
  ListItemAvatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from '@mui/material';

import { PairedPodChips } from 'plantiga-component/Devices/PairedPodChips';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import { useDevices } from 'plantiga-firebase/Devices';
import type { StopwatchWarnings as Warnings } from 'plantiga-firebase/Stopwatch/typedefs';
import { predictPodRunTime } from 'plantiga-util/devices';

import { WarningsListItem } from './WarningMessages';

const useStyles = makeStyles()((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  /* eslint-disable tss-unused-classes/unused-classes */
  outlinedReady: {
    borderColor: theme.palette.success.main,
    background: alpha(theme.palette.success.main, 0.1),
  },
  outlinedWarning: {
    borderColor: theme.palette.warning.main,
    background: alpha(theme.palette.warning.main, 0.1),
  },
  outlinedIgnored: {
    background: alpha(theme.palette.warning.main, 0.1),
  },
  /* eslint-enable tss-unused-classes/unused-classes */
  chipsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  listItemAvatar: {
    display: 'flex',
    fontSize: 32,
  },
}));

type Props = {
  athleteId: string;
  ignoreWarnings: boolean;
  warnings: Warnings;
  onIgnore: (warningKey: keyof Warnings) => void;
  onRemoveAthlete: (athleteId: string) => void;
};

function ListItemTextVerticalAlignMiddle({ children }: React.PropsWithChildren<{}>) {
  return <span style={{ verticalAlign: 'middle' }}>{children}</span>;
}

function PodRunTime({ athleteId }: { athleteId: string }) {
  const pods = useDevices(athleteId);
  const runTime = Object.values(pods).reduce(
    (p, pod) => Math.min(p, predictPodRunTime(pod)),
    Infinity,
  );
  if (Object.keys(pods).length === 0) return <span>No Pods</span>;
  if (runTime < 60 * 1000)
    return (
      <span>
        Pods have, or are about to, <b>run out of battery</b>
      </span>
    );
  return (
    <span>
      Pods have <b>{formatDistance(new Date(runTime), new Date(0))}</b> of runtime left
    </span>
  );
}

export default function AthleteWarnings({
  athleteId,
  ignoreWarnings,
  warnings,
  onIgnore,
  onRemoveAthlete,
}: Props) {
  const { classes } = useStyles();
  const athlete = useAthlete(athleteId);
  const [showWarnings, setShowWarnings] = React.useState(false);

  const handleToggle = React.useCallback(
    (key: keyof Warnings) => {
      onIgnore(key);
      setShowWarnings(false);
    },
    [onIgnore],
  );

  const status = React.useMemo(() => {
    if (isEmpty(warnings)) return 'Ready';
    if (some(warnings)) return 'Warning';
    return 'Ignored';
  }, [warnings]);

  const hasWarnings = !isEmpty(warnings);
  const hasUnhandledWarnings = some(warnings);
  const expanded = showWarnings || (hasUnhandledWarnings && !ignoreWarnings);
  const handleClick = () => setShowWarnings((p) => hasUnhandledWarnings || !p);
  return (
    <Paper variant="outlined" classes={{ outlined: classes?.[`outlined${status}`] }}>
      <List disablePadding>
        <ListItem button onClick={handleClick}>
          <ListItemAvatar className={classes.listItemAvatar}>
            {hasWarnings ? (
              <WarningIcon fontSize="inherit" className={classes.warning} />
            ) : (
              <CheckIcon fontSize="inherit" className={classes.success} />
            )}
          </ListItemAvatar>
          <ListItemText
            secondary={hasWarnings ? athlete.name : <PodRunTime athleteId={athleteId} />}
          >
            <ListItemTextVerticalAlignMiddle>
              {hasWarnings ? <b>WARNING: Data may not record properly </b> : athlete.name}
            </ListItemTextVerticalAlignMiddle>
          </ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              aria-label={
                hasWarnings ? `show warnings for ${athlete.name}` : `show pods for ${athlete.name}`
              }
              size="small"
              disabled={hasUnhandledWarnings && expanded}
              onClick={handleClick}
            >
              {showWarnings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Collapse in={expanded}>
        <div className={classes.chipsContainer}>
          <PairedPodChips showChipsMobile athleteId={athleteId} />
        </div>
        {Object.entries(warnings).map(([warningType, ignored]) => (
          <WarningsListItem
            key={warningType}
            athleteId={athleteId}
            ignored={!ignored}
            warningType={warningType as keyof Warnings}
            toggleWarning={handleToggle}
            removeAthlete={onRemoveAthlete}
          />
        ))}
      </Collapse>
    </Paper>
  );
}
