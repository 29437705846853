import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { AppBar } from '@mui/material';

import Logo from 'plantiga-assets/logo-with-lockup.svg?react';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    height: theme.mixins.toolbar.minHeight,
  },
  logo: {
    ...theme.mixins.plantigaLogo,
  },
}));

export default function UnauthenticatedAppBar() {
  const { classes } = useStyles();
  return (
    <AppBar elevation={0} position="static" className={classes.appBar}>
      <Logo className={classes.logo} />
    </AppBar>
  );
}
