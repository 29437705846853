import React from 'react';

import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';

import { useActivityQuestions } from 'plantiga-component/Questions/useActivityQuestions';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import { formatDuration } from 'plantiga-util/time';

import ActivityProvider from './ActivityProvider';
import DataStatus from './DataStatus';
import NoteDisplay from './NoteDisplay';
import VideoDisplay from './VideoDisplay';

const useStyles = makeStyles()((theme) => ({
  primaryText: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryAction: {
    marginLeft: theme.spacing(2),
    maxWidth: '50%',
  },
}));
const qNameFormat = (name: string) => {
  if (name === 'Body Scan') return 'BOD';
  if (name === 'Energy Level') return 'NRG';
  return name;
};
type Props = {
  readonly activityId: string;
  readonly activity: Activity;
  readonly variant?: 'daily' | 'full-date';
  readonly showValue?: boolean;
  readonly showNameOfPerson?: boolean;
  readonly secondaryAction?: React.ReactNode;
} & React.ComponentProps<typeof ListItem>;

function formatDate(start: Date | undefined, variant: Props['variant']) {
  if (start === undefined) return '?';

  switch (variant) {
    case 'full-date':
      return format(start, 'PP p');
    case 'daily':
    default:
      return format(start, 'p');
  }
}

export default React.forwardRef<HTMLDivElement, Props>(
  (
    {
      activity,
      activityId,
      variant,
      showNameOfPerson,
      showValue = false,
      secondaryAction,
      ...props
    }: Props,
    ref,
  ) => {
    const { classes } = useStyles();
    const [actionRef, setActionRef] = React.useState<HTMLDivElement | null>(null);
    const activityName = activity?.name ?? '?';
    const athlete = useAthlete(activity?.athlete_id);

    const dateTime = formatDate(activity?.start?.toDate?.(), variant);
    const duration = formatDuration(activity?.start?.toDate?.(), activity?.end?.toDate?.());
    const style = {
      paddingRight: actionRef?.getBoundingClientRect()?.width ?? 0,
    };

    const questionRes = useActivityQuestions(activityId);

    return (
      <ListItem ref={ref} {...props} style={style}>
        <ListItemText
          secondary={
            <>
              {dateTime} &#8226; {duration}
              {questionRes.data?.metrics?.map(
                (m) =>
                  m.stored_value && (
                    <>
                      {' '}
                      &#8226; {qNameFormat(m.name)} {m.stored_value}
                    </>
                  ),
              )}
            </>
          }
          secondaryTypographyProps={{ className: classes.text }}
        >
          <div className={classes.primaryText}>
            <span className={classes.text}>
              {showNameOfPerson && athlete?.name}
              {showNameOfPerson && <>&#8226;</>}
              {activityName}
            </span>
            {activity?.source === 'terra' ? (
              <ActivityProvider provider={activity?.labels?.provider} />
            ) : null}
            <DataStatus
              activityId={activityId}
              athleteId={activity.athlete_id}
              activity={activity}
            />
            <NoteDisplay activity={activity} />
            <VideoDisplay activity={activity} activityId={activityId} />
          </div>
        </ListItemText>
        {showValue && secondaryAction && (
          <ListItemSecondaryAction className={classes.secondaryAction} ref={setActionRef}>
            {secondaryAction}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  },
);
