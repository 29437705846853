import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';

import { IconButtonWithLoading } from 'plantiga-common/ButtonWithLoading';
import AutocompleteDevice from 'plantiga-component/Devices/AutocompleteDevice';
import { updateActivity } from 'plantiga-firebase/Activity/actions';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import useCoach from 'plantiga-firebase/Coach/useCoach';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import getDeviceLocationFromId from 'plantiga-util/getDeviceLocationFromId';

import DeviceChips from './DeviceChips';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
  },
  tableRow: {
    height: 80,
  },
  edit: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
}));

type Props = {
  readonly activity: Activity;
  readonly activityId: string;
  readonly teamId: string;
  readonly title: string;
  readonly field: 'device_ids';
};

export default function EditPods({ activity, activityId, teamId, title, field }: Props) {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [selectedDevices, setSelectedDevices] = React.useState<string[]>(activity[field] || []);

  // TODO: refactor into a more general permission architecture
  const { coach } = useCoach();
  const canEditPods = coach?.super_user?.is_admin === true;

  const handleSave = async () => {
    if (activity[field] !== selectedDevices) {
      setLoading(true);
      await updateActivity({
        teamId,
        activityId,
        activity: { ...activity, [field]: selectedDevices },
      });
      setLoading(false);
    }
    setEditing(false);
  };

  const lDeviceId = selectedDevices.find((id) => getDeviceLocationFromId(id) === 'L');
  const rDeviceId = selectedDevices.find((id) => getDeviceLocationFromId(id) === 'R');

  const handleChange = React.useCallback(
    (location: 'L' | 'R') => (d: Device | undefined) => {
      setSelectedDevices((prev) => {
        const withoutOldDevice = prev.filter((id) => getDeviceLocationFromId(id) !== location);
        if (d === undefined) return withoutOldDevice;
        return [...withoutOldDevice, d.id];
      });
    },
    [],
  );

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <div className={classes.edit}>
            <AutocompleteDevice location="L" deviceId={lDeviceId} onChange={handleChange('L')} />
            <AutocompleteDevice location="R" deviceId={rDeviceId} onChange={handleChange('R')} />
          </div>
        ) : (
          <DeviceChips deviceIds={activity[field]} />
        )}
      </TableCell>
      {canEditPods ? (
        <TableCell>
          {editing ? (
            <IconButtonWithLoading loading={loading} disabled={loading} onClick={handleSave}>
              <SaveIcon />
            </IconButtonWithLoading>
          ) : (
            <IconButton onClick={() => setEditing(true)} size="large">
              <EditIcon />
            </IconButton>
          )}
        </TableCell>
      ) : null}
    </TableRow>
  );
}
