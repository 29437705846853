// @ts-nocheck
import { useContext } from 'react';

import ConnectedContext from './ConnectedContext';

export function useConnected() {
  const connected = useContext(ConnectedContext);

  return connected;
}
