import { compact } from 'lodash-es';

import type { Athletes } from 'plantiga-firebase/Athletes/typedefs';
import type { Devices } from 'plantiga-firebase/Devices/typedefs';
import type { Stopwatches } from 'plantiga-firebase/Stopwatch/typedefs';

import { getAthleteWarnings } from './getAthleteWarnings';
import type { AthleteWarningItem } from './getAthleteWarnings';

export default function getFormValues(
  queryParams: { people?: string; activityType?: string; ignoreAll?: string },
  athletes: Athletes,
  devices: Devices,
  stopwatches: Stopwatches,
) {
  const athleteIds = compact(queryParams?.people?.split(',')) ?? [];
  return {
    athleteWarnings: athleteIds.map(
      (athleteId) =>
        ({
          athleteId,
          name: athletes[athleteId].name,
          warnings: getAthleteWarnings(athleteId, devices, stopwatches),
        } as AthleteWarningItem),
    ),
    activityType: queryParams.activityType ?? '',
    labels: {} as { [label: string]: string },
    ignoreAll: queryParams?.ignoreAll === 'true',
  };
}
